/* eslint-disable react/prop-types */
import Drawer from "@mui/material/Drawer";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom";

const MenuDrawer = ({ data, open, handleClose }) => {
  const history = useHistory();

  const handleGoToUrl = (link) => {
    history.push(link);
    handleClose();
  };
  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <List>
        {data.map((val) => (
          <ListItem key={val.label} disablePadding>
            <ListItemButton
              onClick={() => {
                handleGoToUrl(val.link);
              }}
            >
              <ListItemText primary={val.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default MenuDrawer;
