import React from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import { CustomCard, Description } from "./style";
import { PRODUCT_DEFAULT_URL } from "../../constants/other";
import { imgUrl } from "../../helpers/utils";

const CardProduct = ({ item, category }) => {
  const history = useHistory();

  return (
    <Button
      onClick={() => {
        history.push(`/${category.idCategorie}/product/${item.idProduit}`, {
          category: category.libelle,
        });
      }}
    >
      <CustomCard sx={{ height: 280 }}>
        <CardMedia
          component="img"
          height="180"
          image={imgUrl(item.logoProduit || PRODUCT_DEFAULT_URL)}
          alt=""
        />
        <CardContent>
          <Description title={item.libelle} variant="h6">
            {item.libelle}
          </Description>
        </CardContent>
      </CustomCard>
    </Button>
  );
};

CardProduct.propTypes = {
  item: PropTypes.shape().isRequired,
  category: PropTypes.shape().isRequired,
};

export default CardProduct;
