/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as LogoIcon } from "../../assets/logo.svg";
import BasketDrawer from "../BasketDrawer";
import { ROUTES } from "../../constants/routes";
import { logout } from "../../actions/session.actions";
import { CustomIconButton } from "./style";
import { getAllProduct, initBasket } from "../../actions/basket.actions";
import MenuDrawer from "./MenuDrawer";
import { ROLES } from "../../constants/roles";

const dataMenus = [
  {
    label: "Passer commande",
    link: ROUTES.ROOT,
    roles: [ROLES.SUPERVISOR_AGENCY, ROLES.SUPERVISOR_SOCIETY, ROLES.USER],
  },
  {
    label: "Historique",
    link: ROUTES.ORDERS,
    roles: [ROLES.SUPERVISOR_AGENCY, ROLES.SUPERVISOR_SOCIETY],
  },
  {
    label: "Validation",
    link: ROUTES.ORDER_VALIDATION,
    roles: [ROLES.SUPERVISOR_AGENCY, ROLES.SUPERVISOR_SOCIETY, ROLES.USER],
  },
  {
    label: "Contact",
    link: ROUTES.CONTACT,
    roles: [ROLES.SUPERVISOR_AGENCY, ROLES.SUPERVISOR_SOCIETY, ROLES.USER],
  },
];

const Navbar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const user = useSelector((state) => state.session);
  const { total } = useSelector((state) => state.basket);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    await dispatch(initBasket());
    history.push(ROUTES.LOGIN);
  };

  useEffect(() => {
    dispatch(getAllProduct());
  }, []);

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <Box mr={5} sx={{ flexGrow: { xs: 1, md: 0 } }}>
            <CustomIconButton
              onClick={() => {
                history.push(ROUTES.ROOT);
              }}
            >
              <LogoIcon width="110px" height="80px" />
            </CustomIconButton>
          </Box>
          <Box
            sx={{ flexGrow: 1 }}
            display={{ xs: "none", md: "flex" }}
            gap={12}
          >
            {dataMenus
              .filter((val) => val.roles.includes(user?.role))
              .map((item) => (
                <Button
                  key={item.label}
                  sx={{ fontSize: "1rem", color: "black" }}
                  LinkComponent={Link}
                  to={item.link}
                >
                  {item.label}
                </Button>
              ))}
          </Box>
          <Box
            display="flex"
            // width="200px"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* {user?.role === 7 && (
              <Link
                component={LinkReact}
                to={ROUTES.ADMIN}
                sx={{ textDecoration: "none", marginRight: "10px" }}
              >
                Administration
              </Link>
            )} */}
            <IconButton size="large" onClick={handleClick}>
              <AccountCircleIcon sx={{ fontSize: "1.9rem" }} />
            </IconButton>
            {user?.role !== 7 && (
              <IconButton
                size="large"
                onClick={() => {
                  setIsDrawerOpen(true);
                }}
              >
                <Badge badgeContent={total} color="primary">
                  <ShoppingBagIcon />
                </Badge>
              </IconButton>
            )}
            {user?.role !== ROLES.ADMIN && (
              <IconButton
                size="large"
                sx={{ display: { md: "none", xs: "block" }, mt: 0.5 }}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {Boolean(anchorEl) && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(ROUTES.ACCOUNT);
            }}
          >
            Mon compte
          </MenuItem>
          <MenuItem onClick={handleLogout}>Se déconnecter</MenuItem>
        </Menu>
      )}
      <BasketDrawer
        isOpen={isDrawerOpen}
        handleClose={() => {
          setIsDrawerOpen(false);
        }}
      />
      {openDrawer && (
        <MenuDrawer
          data={dataMenus.filter((val) => val.roles.includes(user?.role))}
          open={openDrawer}
          handleClose={() => {
            setOpenDrawer(false);
          }}
        />
      )}
    </>
  );
};

export default Navbar;
